import React from 'react'
import checkListIcon from '../images/icon-check-list.svg'
import store from 'store2'
import { savePDF } from '@progress/kendo-react-pdf'
import logo from '../images/first-5-california-logo.png'

class DocService {
  createPdf (html) {
    savePDF(html, {
      paperSize: 'Letter',
      fileName: 'checks-list.pdf',
      margin: 3
    })
  }
}

const Doc = new DocService()

class BookChecklist extends React.Component {
  constructor (props) {
    super(props)

    const checkValues = store.get('bookCheckValues') || {}
    this.state = {
      checkValues,
      isPreview: false
    }

    this.bodyRef = React.createRef()

    this.toggleItem = this.toggleItem.bind(this)
    this.onSave = this.onSave.bind(this)
    this.onPrint = this.onPrint.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
  }

  toggleItem (id) {
    const { checkValues } = this.state
    checkValues[id] = !(checkValues[id] || false)
    this.setState({ checkValues })
    this.onSave()
  }

  createPdf (html) {
    Doc.createPdf(html)
  }

  onSave () {
    this.setState({ isPreview: false })
    store.set('bookCheckValues', this.state.checkValues)
  }

  onPrint () {
    if (this.state.isPreview) {
      this.createPdf(this.bodyRef.current)
      setTimeout(() => {
        this.setState({ isPreview: false })
      }, 2000)
    } else {
      this.setState({ isPreview: true })
    }
  }

  componentWillUnmount () {
    this.onSave()
  }

  onKeyDown (e) {
    if (e.key === 'Escape') {
      this.props.onClose()
    }
  }

  render () {
    const checklist = this.props.checklist
    const items = checklist.items
    const title = checklist.title

    const { checkValues, isPreview } = this.state

    return (
      <div className='bg-white p-2 mb-4 min-w-2/3' onKeyDown={this.onKeyDown}>
        <div className='flex bg-turqouise mb-2 relative'>
          <img src={checkListIcon} alt='' width='60px' height='60px' />
          <h1 className='text-white text-xl lg:text-2xl font-bold py-4 text-left pr-4'>{isPreview ? 'PDF Preview' : title}</h1>
          <span className='absolute text-white mt-0 sm:mt-2 lg:mt-0 mr-2 lg:mr-0 right-0 cursor-pointer text-2xl font-bold' onClick={this.props.onClose} style={{ top: 16, right: 8 }}>X</span>
        </div>
        <div className='flex justify-between mt-4 mb-4'>
          {isPreview && <button className='bg-coral text-white p-2 w-full lg:w-half flex-1 mr-0 lg:mr-3' onClick={this.onSave}>CANCEL</button>}
          <button className={`bg-coral text-white p-2 w-full lg:w-half flex-1 hidden lg:block ${isPreview ? 'ml-3' : ''}`} onClick={this.onPrint}>{isPreview ? 'DOWNLOAD' : 'Save as PDF'}</button>
        </div>

        {!isPreview && items.map(item => (
          <div key={item.id} htmlFor={item.id} className='flex pb-1 text-left mt-2 mb-2'>
            {item.type === 'Item' && (
              <input
                autoFocus
                type='checkbox'
                id={item.id}
                name={item.id}
                checked={checkValues[item.id] || false}
                onChange={(e) => this.toggleItem(item.id)}
                className='flex m-1'
              />
            )}
            <p className={`flex-1 text-black ml-2 ${item.type === 'Heading' ? 'font-bold' : ''}`} onClick={(e) => this.toggleItem(item.id)}>{item.item}</p>
          </div>
        ))}

        {isPreview &&
          <div>
            <div className='p-10' ref={this.bodyRef}>
              <div className='w-full bg-turqouise p-4 mb-10 flex'>
                <img
                  src={logo}
                  alt='First 5 California Logo'
                  width='144'
                  height='44'
                  className='border-2 border-white h-full bg-white mr-4'
                />
                <span className='font-bold text-white text-2xl mt-1'>{title}</span>
              </div>
              {items.map(item => (
                <div key={item.id} htmlFor={item.id} className='flex pb-1 text-left mt-2 mb-2'>
                  {item.type === 'Item' && (
                    <input
                      type='checkbox'
                      id={item.id}
                      name={item.id}
                      checked={checkValues[item.id] || false}
                      onChange={(e) => this.toggleItem(item.id)}
                      className='flex m-1'
                      disabled='disabled'
                    />
                  )}
                  <p className={`flex-1 text-black ml-2 ${item.type === 'Heading' ? 'font-bold' : ''}`} onClick={(e) => this.toggleItem(item.id)}>{item.item}</p>
                </div>
              ))}
            </div>
          </div>}
      </div>
    )
  }
}

export default BookChecklist
