import React from 'react'

// Contentful
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import { Link } from 'gatsby'

// Custom Contentful components For First 5 CA
import BookSection from './book-section'
import BookChecklist from './book-checklist'
import BookTableOfContents from './book-table-of-contents'

// Custom Marks
const Italic = ({ children }) => <em className='italic'>{children}</em>
const Bold = ({ children }) => (
  <strong className='font-bold'>{children}</strong>
)

// @TODO remove
// THIS IS THE DEFAULT BLOCK WHEN ENTYR TYPE NOT FOUND.
const CustomBlockComponent = ({ title, description }) => (
  <div className='bg-gray-400 p-4 font-bold'>
    <p className='bg-red-200 font-bold'>Warning: Entry Type not found!</p>
    <h2>{title}</h2>
    <p>{description}</p>
  </div>
)

// @TODO remove
const CustomInlineComponent = ({ title, description }) => (
  <span className='bg-gray-400 p-4'>
    {title}: {description}
  </span>
)

// @TODO replace
const CustomAssetComponent = ({ sys, fields }) => {
  if (fields) {
    const fileUrl = fields.file['en-US'].url
    const title = fields.title['en-US']

    let customClass = null

    if (title === 'UC Berkeley Seal') {
      customClass = 'h-32 -ml-2 -mt-24 lg:mt-0'
    } else if (title === 'First 5 California logo') {
      customClass = 'bg-white h-24 border-8 border-white mb-4 -ml-2'
    }

    return (
      <div className='p-2'>
        <img
          src={fileUrl}
          alt={title}
          className={`block ${customClass || 'min-w-full'}`}
        />
      </div>
    )
  }
  return (
    <div>
      Asset Component: This is a <b>{sys.type}</b> of <b>{sys.linkType}</b> type with id of <b>{sys.id}</b>
    </div>
  )
}

const documentToReactComponentsOptions = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: text => <Italic>{text}</Italic>
    // @TODO UNDERLINE & CODE
    // @TODO disable underline in *all* the Contentful Rich Text fields?
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1 className='-ml-1 text-turqouise text-6xl font-bold px-0 py-6 leading-none'>{children}</h1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2 className='text-3xl font-bold text-white mb-10 leading-none'>{children}</h2>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className='mb-4 leading-tight'>{children}</p>
    ),
    [BLOCKS.HR]: node => (
      <hr className='border-gray-400 border-2 border-solid' />
    ),
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const entryType = node.data.target.sys.contentType.sys.id

      switch (entryType) {
        case 'bookTableOfContents':
          return <BookTableOfContents fields={node.data.target.fields} />

        case 'checklists':
        case 'checklist':
          return <BookChecklist checklist={node.data.target.fields} />

        case 'sections':
          return <BookSection node={node} />

        default:
          break
      }

      const { id, linkType, type } = node.data.target.sys
      return (
        <CustomBlockComponent
          title={id}
          description={'Block data ' + linkType + ' ' + type}
        />
      )
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      return (
        <CustomAssetComponent
          sys={node.data.target.sys}
          fields={node.data.target.fields}
        />
      )
    },
    [INLINES.HYPERLINK]: (node, children) => {
      // @TODO if node.data.uri is internal then turn off target and rel.
      const url = node.data.uri
      return (
        <a className='text-black underline cursor-pointer' href={url} target='_blank' rel='noopener noreferrer'>{children}</a>
      )
    },
    [INLINES.EMBEDDED_ENTRY]: node => {
      // @TODO maybe we need to abstract the whold switch from
      // the BLOCKS.EMBEDDED_ENTRY
      const { id, linkType, type } = node.data.target.sys
      return (
        <CustomInlineComponent
          title={id}
          description={'Inline data ' + linkType + ' ' + type}
        />
      )
    }
  },
  // Replace `\n`'s with `<br/>`'s.
  // See https://github.com/contentful/rich-text/blob/master/packages/rich-text-react-renderer/README.md
  renderText: text => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment]
    }, [])
  }
}

function documentToReactChapterComponentsOptions (locale) {
  // default locale if not available
  locale = locale || 'en-US'
  return {
    ...documentToReactComponentsOptions,
    renderNode: {
      ...documentToReactComponentsOptions.renderNode,
      // @TODO move this to the documentToReactComponentsOptions so it can be used in the sidebar
      [INLINES.ENTRY_HYPERLINK]: (node, children) => (
        <Link to={'/' + locale + '/chapter/' + node.data.target.fields.slug['en-US']}>{children}</Link>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className='text-3xl text-turqouise font-bold mt-6 leading-none mb-8'>{children}</h1>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className='list-dot pl-4 mb-6 leading-tight'>{children}</ul>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className='text-2xl font-bold text-turqouise mt-10 mb-4 leading-none'>{children}</h2>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className='mb-4 leading-tight'>{children}</p>
      )
    }
  }
}

function documentToReactSidebarComponentsOptions (locale) {
  locale = locale || 'en-US'
  return {
    ...documentToReactComponentsOptions,
    renderNode: {
      ...documentToReactComponentsOptions.renderNode,
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className='text-base text-black font-bold pt-6 mt-6 leading-none mb-4 border-white border-t-2 border-solid'>{children}</h1>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className='mb-4 leading-tight'>{children}</p>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h1 className='text-base text-black font-bold pt-6 mt-6 leading-none mb-4 border-white border-t-2 border-solid'>{children}</h1>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className='list-dot pl-4 pb-6 leading-tight'>{children}</ul>
      ),
      [INLINES.ENTRY_HYPERLINK]: (node, children) => (
        <Link className='text-coral cursor-pointer' to={'/' + locale + '/chapter/' + node.data.target.fields.slug['en-US']}>{children}</Link>
      )
    }
  }
}

const documentToReactAboutComponentsOptions = {
  ...documentToReactComponentsOptions,
  renderNode: {
    ...documentToReactComponentsOptions.renderNode,
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2 className='text-3xl font-bold text-white mb-10 leading-none bg-turqouise'>{children}</h2>
    ),
    [BLOCKS.HR]: node => (
      <hr className='border-white border-2 border-solid' />
    )
  }
}

const documentToReactSpecialComponentsOptions = {
  ...documentToReactAboutComponentsOptions,
  renderNode: {
    ...documentToReactAboutComponentsOptions.renderNode,
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 className='font-bold mb-4'>{children}</h3>
    )
  }
}

export {
  documentToReactComponentsOptions,
  documentToReactChapterComponentsOptions,
  documentToReactSidebarComponentsOptions,
  documentToReactAboutComponentsOptions,
  documentToReactSpecialComponentsOptions,
  documentToReactComponents
}
