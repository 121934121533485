import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import BookSection from './book-section'
import pasteIcon from '../images/icon-paste.svg'

class BookTableOfContents extends React.Component {
  render () {
    const locale = this.context.intl.locale || 'en-US'
    const resourcesLink = '/' + locale + '/resources'
    return (
      <div className='w-full mt-28 lg:-mt-5'>
        <div className='flex flex-wrap mx-0 lg:mx-0'>
          {this.props.fields.sections['en-US'].map((section, key) => {
            return <BookSection fields={section.fields} key={key} />
          })}
        </div>
        <Link className='flex mb-5 lg:mb-10 flex-col lg:flex-row ml-5 lg:ml-5 mr-5 no-underline' to={resourcesLink}>
          <h2 className='h-16 px-3 flex flex-grow items-center text-2xl text-white font-bold bg-turqouise'>
            <img src={pasteIcon} alt='' className='inline-block w-8 h-8 mr-3 ie-object-contain' />
            <FormattedMessage id='more-help-title' />
          </h2>
          <div className='bg-coral flex justify-center px-10 items-center text-2xl font-bold text-white sm:py-4'><FormattedMessage id='btn.resources' /></div>
        </Link>
      </div>
    )
  }
}

// Make `this.context.intl` available.
BookTableOfContents.contextTypes = {
  intl: PropTypes.object
}

export default BookTableOfContents
