import React from 'react'
import { graphql } from 'gatsby'
import { BLOCKS } from '@contentful/rich-text-types'
import { navigate } from '@reach/router'

import Layout from '../components/layout'
import SEO from '../components/seo'
import searchIcon from '../images/icon-search.svg'
// import SearchHit from '../components/search-hit'
import {
  documentToReactComponents,
  documentToReactComponentsOptions
} from '../components/contentful'
// import Home from '../components/home'

// import algoliasearch from 'algoliasearch/lite'
// import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom'

class HomePage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      searchKey: ''
    }

    this.onSearch = this.onSearch.bind(this)
  }

  onSearch (e) {
    const locale = this.props.pageContext.locale || 'en-US'

    if (e.key === 'Enter') {
      navigate(`/${locale}/search?searchkey=${this.state.searchKey}`)
    }
  }

  render () {
    const locale = this.props.pageContext.locale
    // @TODO move to global, we cannot put this as a formatted message for some reason.
    const searchPlaceholder =
      locale === 'es-MX' ? '¿Que estás buscando?' : 'What are you looking for?'

    const page = this.props.data.contentfulPage
    const document = page.pageContent.json

    let heroImageUrl = ''
    let heroImageTitle = ''
    let heading1Data = {}
    let heading2Data = {}
    let newDocument = {
      ...document
    }

    if (
      document.content &&
      document.content[0].nodeType === BLOCKS.EMBEDDED_ASSET
    ) {
      heroImageUrl = document.content[0].data.target.fields.file['en-US'].url
      // remove "hero" and replace "-" with a space
      heroImageTitle = document.content[0].data.target.fields.title['en-US']
        .replace(/hero/g, '')
        .replace(/-/g, ' ')

      newDocument = {
        ...document,
        content: document.content.filter((c, index) => index !== 0)
      }
    }

    if (
      newDocument.content &&
      newDocument.content[0].nodeType === BLOCKS.HEADING_1
    ) {
      heading1Data = newDocument.content[0]

      newDocument = {
        ...newDocument,
        content: newDocument.content.filter((c, index) => index !== 0)
      }
    }

    if (
      newDocument.content &&
      newDocument.content[0].nodeType === BLOCKS.HEADING_2
    ) {
      heading2Data = newDocument.content[0]

      newDocument = {
        ...newDocument,
        content: newDocument.content.filter((c, index) => index !== 0)
      }
    }

    const reactContentElements = documentToReactComponents(
      newDocument,
      documentToReactComponentsOptions
    )

    return (
      <Layout location={this.props.location} locale={locale}>
        <SEO title='Home' />
        {/* @TODO pull out hero, h1 and h2 and integrate it as shown in layout. */}
        <div className='top-0 left-0 w-full -mt-32 relative faux-multiply-layer-3'>
          <img
            src={heroImageUrl}
            alt={heroImageTitle}
            longdesc='#heroLongDesc'
            className='relative w-full h-hero ie-object-cover object-top faux-multiply-layer-2'
          />
          <div id='heroLongDesc' name='heroLongDesc' className='hidden'>
            {heroImageTitle}
          </div>
          <div className='absolute top-0 w-full h-full faux-multiply-layer-1' />
        </div>
        <div className='container mx-auto -mt-350 z-40 relative'>
          <div className='lg:w-1/2 w-full pt-10 pb-5 px-5 lg:px-0 pr-5 lg:pr-0'>
            <h1 className='w-2/3 lg:w-full text-3xl lg:text-5xl text-white font-bold ml-4 mb-4'>
              {heading1Data.content[0].value}
            </h1>
            <h2 className='text-base lg:text-2xl text-white leading-tight ml-4'>
              {heading2Data.content[0].value}
            </h2>
            <form
              className='block mt-10 mx-0 lg:mx-4 flex mb-20 lg:w-full'
              role='search'
              aria-label='Sitewide'
              onSubmit={e => e.preventDefault()}
            >
              <label className='sr-only text-white' htmlFor='searchInputHome'>
                {searchPlaceholder}
              </label>
              <input
                id='searchInputHome'
                className='w-full h-10 p-2 pl-4 bg-white text-black'
                type='text'
                placeholder={searchPlaceholder}
                arial-label={searchPlaceholder}
                aria-labelledby='searchButtonHeader'
                onChange={e => this.setState({ searchKey: e.target.value })}
                onKeyDown={this.onSearch}
              />
              <button
                onClick={e =>
                  navigate(
                    `/${locale}/search?searchkey=${this.state.searchKey}`
                  )}
                id='searchButtonHeader'
                className='bg-coral w-10 h-10'
              >
                <img
                  src={searchIcon}
                  alt=''
                  className='w-10 h-10 p-3 ie-object-contain'
                />
                <span className='sr-only text-white'>Search</span>
              </button>
            </form>
          </div>
          {reactContentElements}
        </div>
      </Layout>
    )
  }
}

export default HomePage

export const homePageQuery = graphql`
  query homePageQuery($locale: String!) {
    contentfulPage(slug: { eq: "home" }, node_locale: { eq: $locale }) {
      id
      title
      pageContent {
        json
      }
    }
  }
`
