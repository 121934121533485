import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

class BookSection extends React.Component {
  getPermalink (chapter, locale) {
    return '/' + locale + '/chapter/' + chapter.fields.slug['en-US']
  }

  render () {
    const localeDefault = 'en-US'

    const chapters = this.props.fields.chapters[localeDefault]

    const locale = this.context.intl.locale || localeDefault

    const title = this.props.fields.title[locale] || this.props.fields.title[localeDefault]
    // @TODO can we remove this level of locale in the CMS?
    // we only have en-US, we only care about the deeper locale
    const icon = this.props.fields.icon[localeDefault]

    // @TODO this is fragile, improve error checking
    const iconUrl = icon.fields.file[locale]
      ? icon.fields.file[locale].url
      : icon.fields.file[localeDefault].url

    return (
      <div className='w-full lg:w-half bg-gray mb-5 lg:mb-10 lg:ml-5 lg:mr-5 mx-5'>
        <h2 className='h-16 px-3 flex items-center text-2xl text-white font-bold bg-turqouise'>
          <img src={iconUrl} className='inline-block w-10 h-10 mr-3 ie-object-contain' alt='' width='40' height='40' />
          {title}
        </h2>
        <div className='h-auto p-4 py-0 bg-gray'>
          <ul>
            {chapters.map((chapter, index) => {
              const chapterPermalink = this.getPermalink(chapter, locale)
              return (
                <li key={chapterPermalink} className='-mx-4 px-4 block text-black hover:bg-coral hover:text-white'>
                  {/* @TODO create Link to inherit from Gatsby's Link and add in the locale functionality so we don't need to pass in locale to `getPermalink()` */}
                  <Link to={chapterPermalink} className={`${index !== chapters.length - 1 ? 'border-white border-b-2' : ''} py-3 px-4 block text-black hover:bg-coral hover:text-white hover:border-coral`}>
                    {chapter.fields.title[locale] || chapter.fields.title[localeDefault]}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }
}

// Make `this.context.intl` available.
BookSection.contextTypes = {
  intl: PropTypes.object
}

export default BookSection
